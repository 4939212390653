import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { Driver } from '@/shared/interfaces/driver.interface'

export class DriverInfoModel extends CommonModel {
  public data: Driver | null = null

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.setData(this.baseData)
    this.clearBaseData()
  }

  private setData (data: any): void {
    this.data = {
      boxNumber: data.box_number,
      cityInfo: {
        id: data.city.id,
        name: data.city.name,
        countryName: data.city.country_name
      },
      vehicleId: {
        name: data?.vehicle_id?.name ?? '',
        model: data?.vehicle_id?.model ?? '',
        vehicleType: {
          id: data?.vehicle_id?.vehicle_type?.id ?? '',
          value: data?.vehicle_id?.vehicle_type?.value ?? ''
        }
      },
      createdAt: data.created_at,
      darkStoreId: data.darkstore_id,
      driverInfo: {
        firstName: data.driver_info.first_name,
        lastName: data.driver_info.last_name,
        userName: data.driver_info.username,
        userPhone: data.driver_info.user_phone_number
      },
      driverLicenseNo: data.driver_license_no,
      driverStatus: {
        id: data.driver_status.id,
        name: data.driver_status.name,
        value: data.driver_status.value
      },
      driverAddress: {
        addressBuilding: data.driver_address.address_building,
        addressDistrict: data.driver_address.address_district,
        addressFlat: data.driver_address.address_flat,
        addressFloor: data.driver_address.address_floor,
        addressNearestLandmark: data.driver_address.address_nearest_landmark,
        addressNeighborhood: data.driver_address.address_neighborhood
      },
      id: data.id,
      isClockIn: data.is_clock_in,
      license: data.license,
      shift: {
        id: data.shift.id,
        shiftEnd: data.shift.shift_end,
        shiftStart: data.shift.shift_start
      },
      lastLocation: {
        latitude: data.last_location.latitude,
        longitude: data.last_location.longitude
      },
      trackingId: data.tracking_id
    }
  }
}
