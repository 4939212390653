import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DarkstoreDriverRevenueInterface } from '@/shared/interfaces/reports/darkstore-driver-revenue.interface'
import moment from 'moment'

export class DarkstoreDriverRevenueModel extends CommonModel {
  public data: DarkstoreDriverRevenueInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DarkstoreDriverRevenueInterface {
    return {
      driverFullName: data?.driver_full_name,
      username: data?.username,
      driverId: data.driver_id,
      totalOrders: data.total_orders,
      totalCollectedOrderAmount: data.total_collected_order_amount,
      orderStatusId: data.order_status_id,
      orderStatus: data.order_status,
      items: data.items.map((i: any) => {
        return {
          ...i,
          order_created_at: i.order_created_at ? moment(i.order_created_at).format('YYYY-MM-DD HH:mm') : '',
          order_delivered_at: i.order_delivered_at ? moment(i.order_delivered_at).format('YYYY-MM-DD HH:mm') : ''
        }
      }),
      trackingId: data.tracking_id
    }
  }
}
