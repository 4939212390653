import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiBase, apiEndpoints, locationsApiBase } from '@/shared/endpoints/api'
import { ReportsState } from '@/store/modules/reports/types'
import { ReportsMutations } from '@/store/modules/reports/mutations'
import { ReportProductivityModel } from '@/shared/models/reports/report-productivity.model'
import { ViewOrdersDriversModel } from '@/shared/models/reports/view-orders-drivers.model'
import { OrderByStatusModel } from '@/shared/models/reports/order-by-status.model'
import { ReportProductivityDayModel } from '@/shared/models/reports/report-productivity-day.model'
import moment from 'moment'
import { OrderByStatusMonthlyModel } from '@/shared/models/reports/orders-by-status-monthly.model'
import { ViewOrdersTimingModel } from '@/shared/models/reports/view-orders-timing.model'
import { ViewDriversTimingPerDayModel } from '@/shared/models/reports/view-drivers-timing-per-day.model'
import { ViewDriversTimingPerMonthModel } from '@/shared/models/reports/view-drivers-timing-per-month.model'
import axiosInstance from '@/shared/utils/axiosInstance'
import { DriverActivityModel } from '@/shared/models/reports/driver-activity.model'
import { DriverProductivityModel } from '@/shared/models/reports/driver-productivity.model'
import { DriverDeliveryModel } from '@/shared/models/reports/driver-delivery.model'
import { DarkstoreDeliveryMetricsModel } from '@/shared/models/reports/darkstore-delivery-metrics.model'
import { ZoneDeliveryMetricsModel } from '@/shared/models/reports/zone-delivery-metrics.model'
import { DriverPerformanceModel } from '@/shared/models/reports/driver-performance.model'
import { DarkstoreOrderPeakHoursModel } from '@/shared/models/reports/darkstore-order-peak-hours.model'
import { HourlyDeliveryTimeAnalysisModel } from '@/shared/models/reports/hourly-delivery-time-analysis.model'
import { DriverRevenueModel } from '@/shared/models/reports/driver-revenue.model'
import { DarkstoreDriverRevenueModel } from '@/shared/models/reports/darkstore-driver-revenue.model'
import { AggregateDarkstoreRevenue } from '@/shared/models/reports/aggregate-darkstore-revenue'

export enum ReportActions {
  FETCH_REPORT_TYPES = 'FETCH_REPORT_TYPES',
  FETCH_REPORTS = 'FETCH_REPORTS'
}

export const actions: ActionTree<ReportsState, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ReportActions.FETCH_REPORT_TYPES]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.report.reportTypes.method,
      url: apiEndpoints.report.reportTypes.url
    }).then(resp => {
      const payload = [...resp.data.data,
        { id: 'Drivers_activity_per_darkstore_per_shift', name: 'Drivers_activity_per_darkstore_per_shift' },
        { id: 'Drivers_productivity', name: 'Drivers_productivity' },
        { id: 'Driver_delivery', name: 'Driver_delivery' },
        { id: 'Hourly_delivery_time_analysis', name: 'Hourly_delivery_time_analysis' },
        { id: 'Driver_revenue', name: 'Driver_revenue' },
        { id: 'Darkstore_driver_revenue', name: 'Darkstore_driver_revenue' },
        { id: 'Aggregate_darkstore_revenue', name: 'Aggregate_darkstore_revenue' }
      ]
      context.commit(ReportsMutations.SET_REPORT_TYPES, payload)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [ReportActions.FETCH_REPORTS] (context, payload) {
    context.commit(payload.export ? ReportsMutations.EXPORT_REPORTS_LOADING_EVENT : ReportsMutations.REPORTS_LOADING_EVENT, true)
    const params = new URLSearchParams()
    if (payload.pageNumber) {
      params.append('page_number', payload.pageNumber)
    }
    if (payload.pageSize) {
      params.append('per_page', payload.pageSize)
    }
    if (payload.export) {
      params.append('export', '1')
    }
    if (payload.sort) {
      params.append('sort_direction', payload.sort)
      params.append('order_by', payload.orderBy)
    }
    if (payload.dates_month) {
      params.append('m', (Number(payload.dates_month)).toString())
      params.append('y', payload.dates_year)
    }
    if (payload.productivityDate) {
      params.append('m', moment(payload.productivityDate).format('MM'))
      params.append('y', moment(payload.productivityDate).format('YYYY'))
      params.append('d', moment(payload.productivityDate).format('DD'))
    }
    if (payload.datetime) {
      params.append('m', moment(payload.datetime).format('MM'))
      params.append('y', moment(payload.datetime).format('YYYY'))
      params.append('d', moment(payload.datetime).format('DD'))
    }
    if (payload.username) {
      params.append('search', payload.username)
    }
    if (payload.search) {
      params.append('search', payload.search)
    }
    if (payload.darkstoreName) {
      params.append('darkstore_name', payload.darkstoreName)
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('darkstore_ids', el)
      })
    }
    if (payload.darkstore_Ids && payload.darkstore_Ids.split(':')[1] !== '') {
      payload.darkstore_Ids.split(':')[1].split(',').forEach((el: any) => {
        params.append('dark_store_ids', el)
      })
    }
    if (payload.darkstoreId) {
      params.append('dark_store_id', payload.darkstoreId)
    }
    if (payload.orderId) {
      params.append('order_id', payload.orderId)
    }
    if (payload.orderIds) {
      params.append('order_ids', payload.orderIds)
    }
    if (payload.ordersCount) {
      params.append('orders_count', payload.ordersCount)
    }
    if (payload.orderCount) {
      params.append('order_count', payload.orderCount)
    }
    if (payload.userAddress) {
      params.append('user_address', payload.userAddress)
    }
    if (payload.customerCode) {
      params.append('customer_code', payload.customerCode)
    }
    if (payload.orderTotal) {
      params.append('order_total', payload.orderTotal)
    }
    if (payload.binNo) {
      params.append('order_bin_number', payload.binNo)
    }
    if (payload.orderCreatedAt) {
      params.append('order_created_at', payload.orderCreatedAt)
    }
    if (payload.orderDeliveredAt_from) {
      params.append('order_delivered_at_from', moment(payload.orderDeliveredAt_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('order_delivered_at_to', moment(payload.orderDeliveredAt_to).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.orderCreatedAt_from) {
      params.append('order_created_at_from', moment(payload.orderCreatedAt_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('order_created_at_to', moment(payload.orderCreatedAt_to).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.createdAt_from) {
      params.append('order_created_at_from', moment(payload.createdAt_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('order_created_at_to', moment(payload.createdAt_to).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.locationDate_from) {
      params.append('from_datetime', moment(payload.locationDate_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('to_datetime', moment(payload.locationDate_to).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.status) {
      params.append('order_status_id', payload.status)
    }
    if (payload.statusName) {
      params.append('order_status', payload.statusName)
    }
    if (payload.orderStatusId) {
      params.append('order_status_id', payload.orderStatusId)
    }
    if (payload.vehicleTypeId) {
      params.append('vehicle_type_id', payload.vehicleTypeId)
    }
    if (payload.activity_from) {
      params.append('activity_start_date', moment(payload.activity_from).format('YYYY-MM-DD HH:mm:ss'))
      params.append('activity_end_date', moment(payload.activity_to).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.minDistance) {
      params.append('min_distance', payload.minDistance)
    }
    if (payload.maxDistance) {
      params.append('max_distance', payload.maxDistance)
    }
    if (payload.minOrderTotal) {
      params.append('min_order_total', payload.minOrderTotal)
    }
    if (payload.maxOrderTotal) {
      params.append('max_order_total', payload.maxOrderTotal)
    }
    if (payload.shiftStartTime) {
      params.append('shift_start_time', payload.shiftStartTime)
    }
    if (payload.shiftEndTime) {
      params.append('shift_end_time', payload.shiftEndTime)
    }
    if (payload.driverId) {
      params.append('driver_id', payload.driverId)
    }
    if (payload.trackingId) {
      params.append('tracking_id', payload.trackingId)
    }
    if (!payload.sort) {
      switch (payload.reportType.name) {
        case 'Drivers_activity_per_darkstore_per_shift':
          if (!payload.activity_from) {
            const today = new Date()
            const yesterday = new Date(today)
            yesterday.setDate(today.getDate() - 1)
            params.append('activity_start_date', moment(yesterday).format('YYYY-MM-DD') + ' 00:00:00')
            params.append('activity_end_date', moment(yesterday).format('YYYY-MM-DD') + ' 23:59:59')
          }
          params.append('sort_direction', 'desc')
          params.append('order_by', 'creation_date')
          break
        case 'Driver_delivery':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'created_at')
          break
        case 'view_drivers_productivity_per_month':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'order_created_at')
          break
        case 'view_drivers_productivity_per_day':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'order_created_at')
          break
        case 'view_orders_and_drivers':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'order_created_at')
          break
        case 'view_orders_by_status_per_day':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'order_created_at')
          break
        case 'view_orders_by_status_per_month':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'order_created_at')
          break
        case 'view_orders_timing':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'created_at')
          break
        case 'view_drivers_timing_per_day':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'created_at')
          break
        case 'view_drivers_timing_per_month':
          params.append('sort_direction', 'desc')
          params.append('order_by', 'created_at')
          break
      }
    }
    let method = apiEndpoints.report.list.method
    let url = `${apiEndpoints.report.list.url}${payload.reportType.id}`

    if (payload.reportType.name === 'view_orders_and_drivers') {
      method = apiEndpoints.report.ordersAndDrivers.method
      url = apiEndpoints.report.ordersAndDrivers.url
    } else if (payload.reportType.name === 'Drivers_activity_per_darkstore_per_shift') {
      method = apiEndpoints.report.activity.method
      url = apiEndpoints.report.activity.url
    } else if (payload.reportType.name === 'Drivers_productivity') {
      method = apiEndpoints.report.productivity.method
      url = apiEndpoints.report.productivity.url
    } else if (payload.reportType.name === 'Driver_delivery') {
      method = apiEndpoints.report.delivery.method
      url = apiEndpoints.report.delivery.url
    } else if (payload.reportType.name === 'Darkstore_delivery_metrics') {
      method = apiEndpoints.report.dark_store_delivery_metrics.method
      url = apiEndpoints.report.dark_store_delivery_metrics.url
    } else if (payload.reportType.name === 'Zone_delivery_metrics') {
      method = apiEndpoints.report.zone_delivery_metrics.method
      url = apiEndpoints.report.zone_delivery_metrics.url
    } else if (payload.reportType.name === 'Driver_performance') {
      if (!payload.locationDate_from || !payload.locationDate_to) {
        const currentDate = new Date()
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        params.append('from_datetime', moment(firstDayOfMonth).format('YYYY-MM-DD HH:mm:ss'))
        params.append('to_datetime', moment(currentDate).format('YYYY-MM-DD HH:mm:ss'))
      }
      method = apiEndpoints.report.driverPerformance.method
      url = apiEndpoints.report.driverPerformance.url
    } else if (payload.reportType.name === 'Darkstore_order_peak_hours') {
      params.append('end_date', payload.endDate || moment().format('YYYY-MM-DD'))
      params.append('start_date', payload.startDate || moment().add(-7, 'days').format('YYYY-MM-DD'))
      params.append('start_hour', payload.startHour || 8)
      params.append('end_hour', payload.endHour || 18)
      if (payload.darkstoreIdList) {
        payload.darkstoreIdList.forEach((id: any) => {
          params.append('dark_store_ids', id)
        })
      }
      method = apiEndpoints.report.darkstoreOrderPeakHours.method
      url = apiEndpoints.report.darkstoreOrderPeakHours.url
    } else if (payload.reportType.name === 'Hourly_delivery_time_analysis') {
      params.append('delivery_time_apply', payload.deliveryTimeApply || 1)
      params.append('end_date', payload.endDate || moment().format('YYYY-MM-DD HH:mm:ss'))
      params.append('start_date', payload.startDate || moment().add(-7, 'days').format('YYYY-MM-DD HH:mm:ss'))
      params.append('start_hour', payload.startHour || 8)
      params.append('end_hour', payload.endHour || 18)
      if (payload.darkstoreIdList) {
        payload.darkstoreIdList.forEach((id: any) => {
          params.append('dark_store_ids', id)
        })
      }
      method = apiEndpoints.report.hourlyDeliveryTimeAnalysis.method
      url = apiEndpoints.report.hourlyDeliveryTimeAnalysis.url
    } else if (payload.reportType.name === 'Driver_revenue') {
      method = apiEndpoints.report.driverRevenue.method
      url = apiEndpoints.report.driverRevenue.url
    } else if (payload.reportType.name === 'Darkstore_driver_revenue') {
      method = apiEndpoints.report.darkstoreDriverRevenue.method
      url = apiEndpoints.report.darkstoreDriverRevenue.url
    } else if (payload.reportType.name === 'Aggregate_darkstore_revenue') {
      method = apiEndpoints.report.aggregateDarkstoreRevenue.method
      url = apiEndpoints.report.aggregateDarkstoreRevenue.url
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        baseURL: apiBase,
        method,
        url,
        params
      }).then(resp => {
        context.commit(payload.export ? ReportsMutations.EXPORT_REPORTS_LOADING_EVENT : ReportsMutations.REPORTS_LOADING_EVENT, false)
        let model: any
        switch (payload.reportType.name) {
          case 'Driver_delivery':
            model = new DriverDeliveryModel(resp.data)
            break
          case 'Drivers_activity_per_darkstore_per_shift':
            model = new DriverActivityModel(resp.data)
            break
          case 'Drivers_productivity':
            model = new DriverProductivityModel(resp.data)
            break
          case 'view_drivers_productivity_per_month':
            model = new ReportProductivityModel(resp.data)
            break
          case 'view_drivers_productivity_per_day':
            model = new ReportProductivityDayModel(resp.data)
            break
          case 'view_orders_and_drivers':
            model = new ViewOrdersDriversModel(resp.data)
            break
          case 'view_orders_by_status_per_day':
            model = new OrderByStatusModel(resp.data)
            break
          case 'view_orders_by_status_per_month':
            model = new OrderByStatusMonthlyModel(resp.data)
            break
          case 'view_orders_timing':
            model = new ViewOrdersTimingModel(resp.data)
            break
          case 'view_drivers_timing_per_day':
            model = new ViewDriversTimingPerDayModel(resp.data)
            break
          case 'view_drivers_timing_per_month':
            model = new ViewDriversTimingPerMonthModel(resp.data)
            break
          case 'Darkstore_delivery_metrics':
            model = new DarkstoreDeliveryMetricsModel(resp.data)
            break
          case 'Zone_delivery_metrics':
            model = new ZoneDeliveryMetricsModel(resp.data)
            break
          case 'Driver_performance':
            model = new DriverPerformanceModel(resp.data)
            break
          case 'Darkstore_order_peak_hours':
            model = new DarkstoreOrderPeakHoursModel(resp.data)
            break
          case 'Hourly_delivery_time_analysis':
            model = new HourlyDeliveryTimeAnalysisModel(resp.data)
            break
          case 'Driver_revenue':
            model = new DriverRevenueModel(resp.data)
            break
          case 'Darkstore_driver_revenue':
            model = new DarkstoreDriverRevenueModel(resp.data)
            break
          case 'Aggregate_darkstore_revenue':
            model = new AggregateDarkstoreRevenue(resp.data)
            break
        }
        const data = {
          data: model.data,
          pagination: model.paginationData
        }
        switch (payload.reportType.name) {
          case 'Darkstore_delivery_metrics':
            context.commit(payload.export ? ReportsMutations.SET_DARKSTORE_DELIVERY_METRICS_EXPORT : ReportsMutations.SET_DARKSTORE_DELIVERY_METRICS, data)
            break
          case 'Zone_delivery_metrics':
            context.commit(payload.export ? ReportsMutations.SET_ZONE_DELIVERY_METRICS_EXPORT : ReportsMutations.SET_ZONE_DELIVERY_METRICS, data)
            break
          case 'Driver_delivery':
            context.commit(payload.export ? ReportsMutations.SET_DRIVER_DELIVERY_EXPORT : ReportsMutations.SET_DRIVER_DELIVERY, data)
            break
          case 'Drivers_activity_per_darkstore_per_shift':
            context.commit(payload.export ? ReportsMutations.SET_DRIVER_ACTIVITY_EXPORT : ReportsMutations.SET_DRIVER_ACTIVITY, data)
            break
          case 'Drivers_productivity':
            context.commit(payload.export ? ReportsMutations.SET_DRIVER_PRODUCTIVITY_EXPORT : ReportsMutations.SET_DRIVER_PRODUCTIVITY, data)
            break
          case 'view_drivers_productivity_per_month':
            context.commit(payload.export ? ReportsMutations.SET_PRODUCTIVITY_EXPORT_REPORTS : ReportsMutations.SET_PRODUCTIVITY_REPORTS, data)
            break
          case 'view_drivers_productivity_per_day':
            context.commit(payload.export ? ReportsMutations.SET_PRODUCTIVITY_DAY_EXPORT_REPORTS : ReportsMutations.SET_PRODUCTIVITY_DAY_REPORTS, data)
            break
          case 'view_orders_and_drivers':
            context.commit(payload.export ? ReportsMutations.SET_VIEW_ORDER_DRIVER_EXPORT_REPORTS : ReportsMutations.SET_VIEW_ORDER_DRIVER_REPORTS, data)
            break
          case 'view_orders_by_status_per_day':
            context.commit(payload.export ? ReportsMutations.SET_ORDERS_BY_STATUS_EXPORT_REPORTS : ReportsMutations.SET_ORDERS_BY_STATUS_REPORTS, data)
            break
          case 'view_orders_by_status_per_month':
            context.commit(payload.export ? ReportsMutations.SET_ORDERS_BY_STATUS_MONTHLY_EXPORT_REPORTS : ReportsMutations.SET_ORDERS_BY_STATUS_MONTHLY_REPORTS, data)
            break
          case 'view_orders_timing':
            context.commit(payload.export ? ReportsMutations.SET_ORDERS_TIMING_EXPORT_REPORTS : ReportsMutations.SET_ORDERS_TIMING_REPORTS, data)
            break
          case 'view_drivers_timing_per_day':
            context.commit(payload.export ? ReportsMutations.SET_DRIVERS_TIMING_PER_DAY_EXPORT_REPORTS : ReportsMutations.SET_DRIVERS_TIMING_PER_DAY_REPORTS, data)
            break
          case 'view_drivers_timing_per_month':
            context.commit(payload.export ? ReportsMutations.SET_DRIVERS_TIMING_PER_MONTH_EXPORT_REPORTS : ReportsMutations.SET_DRIVERS_TIMING_PER_MONTH_REPORTS, data)
            break
          case 'Driver_performance':
            context.commit(payload.export ? ReportsMutations.SET_DRIVER_PERFORMANCE_EXPORT : ReportsMutations.SET_DRIVER_PERFORMANCE, data)
            break
          case 'Darkstore_order_peak_hours':
            context.commit(payload.export ? ReportsMutations.SET_DARKSTORE_ORDER_PEAK_HOURS_EXPORT : ReportsMutations.SET_DARKSTORE_ORDER_PEAK_HOURS, data)
            break
          case 'Hourly_delivery_time_analysis':
            context.commit(payload.export ? ReportsMutations.SET_HOURLY_DELIVERY_TIME_ANALYSIS_EXPORT : ReportsMutations.SET_HOURLY_DELIVERY_TIME_ANALYSIS, data)
            break
          case 'Driver_revenue':
            context.commit(payload.export ? ReportsMutations.SET_DRIVER_REVENUE_EXPORT : ReportsMutations.SET_DRIVER_REVENUE, data)
            break
          case 'Darkstore_driver_revenue':
            context.commit(payload.export ? ReportsMutations.DARKSTORE_DRIVER_REVENUE_EXPORT : ReportsMutations.DARKSTORE_DRIVER_REVENUE, data)
            break
          case 'Aggregate_darkstore_revenue':
            context.commit(payload.export ? ReportsMutations.DARKSTORE_AGGREGATE_REVENUE_EXPORT : ReportsMutations.DARKSTORE_AGGREGATE_REVENUE, data)
            break
        }
        resolve(data)
      }).catch(err => {
        context.commit(payload.export ? ReportsMutations.EXPORT_REPORTS_LOADING_EVENT : ReportsMutations.REPORTS_LOADING_EVENT, false)
        throw new Error(err)
      })
    })
  }
}
