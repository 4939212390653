import { Method as apiMethod } from 'axios'

export const reportEndpoints = {
  reportTypes: {
    method: 'get' as apiMethod,
    url: '/reports/types'
  },
  list: {
    method: 'get' as apiMethod,
    url: '/reports/'
  },
  activity: {
    method: 'get' as apiMethod,
    url: '/reports/driver/activity'
  },
  productivity: {
    method: 'get' as apiMethod,
    url: '/reports/driver/productivity'
  },
  ordersAndDrivers: {
    method: 'get' as apiMethod,
    url: '/reports/driver/orders_and_drivers'
  },
  delivery: {
    method: 'get' as apiMethod,
    url: '/reports/driver/delivery'
  },
  dark_store_delivery_metrics: {
    method: 'get' as apiMethod,
    url: '/reports/darkstore/delivery_metrics'
  },
  zone_delivery_metrics: {
    method: 'get' as apiMethod,
    url: '/reports/zone/delivery_metrics'
  },
  driverPerformance: {
    method: 'get' as apiMethod,
    url: 'reports/driver/performance_report'
  },
  darkstoreOrderPeakHours: {
    method: 'get' as apiMethod,
    url: '/reports/darkstore/peak_hours'
  },
  hourlyDeliveryTimeAnalysis: {
    method: 'get' as apiMethod,
    url: '/reports/delivery_time/hourly'
  },
  driverRevenue: {
    method: 'get' as apiMethod,
    url: '/reports/driver/revenue'
  },
  darkstoreDriverRevenue: {
    method: 'get' as apiMethod,
    url: '/reports/darkstore/driver_revenue'
  },
  aggregateDarkstoreRevenue: {
    method: 'get' as apiMethod,
    url: '/reports/darkstore/revenue_report'
  }

}
